import { Fragment, useState, useEffect, useRef } from "react";
import { fromJS } from "immutable";
import { api } from "../../lib/api";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/outline";

const DevSign = () => {
  return (
    <main className="flex-1 overflow-y-auto focus:outline-none" tabIndex="0">
      <div className="relative max-w-4xl mx-auto md:px-8 xl:px-0">
        <div className="pt-10 pb-16">
          <div className="px-4 sm:px-6 md:px-0">
            <h1 className="text-3xl font-extrabold text-gray-900">签名</h1>
          </div>
          <div className="px-4 sm:px-6 md:px-0">
            <div className="py-6">
              <div>
                <p className="max-w-2xl text-sm text-gray-500">
                  开发测试用时申请的临时签名
                </p>
              </div>
            </div>
          </div>
          <Form />
        </div>
      </div>
    </main>
  );
};

const Form = () => {
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const [envs, setEnvs] = useState(() => fromJS([]));
  const [permissions, setPermissions] = useState(() => fromJS([]));
  const [users, setUsers] = useState(() => fromJS([]));
  const [req, setReq] = useState(() => fromJS({}));
  const [token, setToken] = useState("");

  console.log(req.toJS());
  useEffect(() => {
    (async () => {
      const result = await api.get("/envs");
      const env = fromJS(result.data || []);
      setEnvs(env);
      setReq((req) => {
        return req.set("env", env.getIn([0, "name"], ""));
      });
    })();

    (async () => {
      const result = await api.get("/permissions");
      const permissions = fromJS(result.data || []);
      setPermissions(permissions);
      setReq((req) => {
        return req.set("permission", permissions.getIn([0, "name"], ""));
      });
    })();

    (async () => {
      const result = await api.get("/users");
      const users = fromJS(result.data || []);
      setUsers(users);
      setReq((req) => {
        return req.set("userId", parseInt(users.getIn([0, "id"], "0")));
      });
    })();
  }, []);

  return (
    <>
      <form
        className="space-y-8 divide-y divide-gray-200"
        onSubmit={(e) => {
          e.preventDefault();
          console.log(req.toJS());
          const { userId, env, permission } = req.toJS();
          api
            .post("https://devsign.hemayanjing.com/sign", {
              userId,
              request_permission: permission,
              env_name: env,
            })
            .then((res) => {
              const token = res.data;
              setToken(token);
              navigator.clipboard.writeText(token);
              setOpen(true);
            });
        }}
      >
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
            <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="user"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  用户
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    id="user"
                    name="user"
                    className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    value={req.get("userId") || 0}
                    onChange={(e) => {
                      setReq((req) => {
                        return req.set("userId", parseInt(e.target.value));
                      });
                    }}
                  >
                    {users.map((v) => {
                      return (
                        <option key={v.get("id")} value={v.get("id")}>
                          {v.get("description")}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="permission"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  权限
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    id="permission"
                    name="permission"
                    className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    value={req.get("permission") || ""}
                    onChange={(e) => {
                      setReq((req) => {
                        return req.set("permission", e.target.value);
                      });
                    }}
                  >
                    {permissions.map((v) => {
                      return (
                        <option key={v.get("id")} value={v.get("name")}>
                          {v.get("name")}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="env"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  环境
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <select
                    id="env"
                    name="env"
                    className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    value={req.get("env") || ""}
                    onChange={(e) => {
                      setReq((req) => {
                        return req.set("env", e.target.value);
                      });
                    }}
                  >
                    {envs.map((v) => {
                      return (
                        <option key={v.get("id")} value={v.get("name")}>
                          {v.get("name")}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-end">
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              申请 Token
            </button>
          </div>
        </div>
      </form>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                  <div>
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                      <CheckIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        申请成功
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          TOKEN: {token.slice(0, 10)}...{token.slice(-10)}{" "}
                          已成功复制到剪切板
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-1 sm:gap-3 sm:grid-flow-row-dense">
                    <button
                      type="button"
                      className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      关闭
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default DevSign;
