import axios from "axios";
import ms from "ms";
import _get from "lodash/get";

const BASEURL = process.env.REACT_APP_BASEURL || "http://localhost:8003";

export const _api = axios.create({
  baseURL: BASEURL,
  //baseURL: "http://localhost:8003",
});

let checkAndUpdateTokenPromise;
export const api = new Proxy(_api, {
  get(target, key, context) {
    if (["get", "post", "put", "delete"].includes(key)) {
      if (!checkAndUpdateTokenPromise) {
        checkAndUpdateTokenPromise = checkAndUpdateToken();
      }

      return (...args) => {
        return checkAndUpdateTokenPromise
          .then(() => (checkAndUpdateTokenPromise = null))
          .then(() => Reflect.get(target, key, context)(...args));
      };
    }

    return Reflect.get(target, key, context);
  },
});

function decode(token) {
  return JSON.parse(window.atob(token.split(".")[1]));
}

const checkAndUpdateToken = async () => {
  const accessToken = window.localStorage.accessToken;
  const decoded = decode(accessToken);
  if (decoded && decoded.exp * 1000 - ms("3m") > Date.now()) {
    return accessToken;
  }

  const refreshToken = window.localStorage.refreshToken;
  if (!refreshToken && window.location.pathname !== "/") {
    alert("请求失败，请重新登录");

    window.location.href = "/";
    return;
  }
  if (!refreshToken) {
    return Promise.reject();
  }
  let res;
  try {
    res = await _api.post("/refresh_token", {
      refresh_token: refreshToken,
    });
  } catch (e) {
    if (window.location.pathname !== "/") {
      alert(_get(e, "response.data", e.message));
      window.location.href = "/";
    }
    throw e;
  }
  const token = _get(res, "data.access_token");

  window.localStorage.accessToken = token;
  api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  return token;
};

if (window.localStorage.accessToken) {
  api.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${window.localStorage.accessToken}`;
}
