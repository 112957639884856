import { useEffect } from "react";
import _get from "lodash/get";
import { api, _api } from "../../lib/api";
import { useNavigate } from "react-router-dom";

const APPID = process.env.REACT_APP_APPID || "2b08e7e30fdb4a1181f7ad68b866011a";

const Login = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const url = new URL(window.location.href);
    const code = url.searchParams.get("code");
    if (!code) {
      return;
    }

    (async function () {
      let res;
      try {
        res = await _api.post("/fetch_token", {
          code: code,
        });
      } catch (e) {
        return alert(_get(e, "response.data", e.message));
      }
      const accessToken = _get(res, "data.access_token");
      const refreshToken = _get(res, "data.refresh_token");
      api.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
      window.localStorage.accessToken = accessToken;
      window.localStorage.refreshToken = refreshToken;
      navigate("/home/app");
    })();
  }, []); // eslint-disable-line
  return (
    <div className="bg-gray-100">
      <div className="container mx-auto h-screen">
        <div className="flex">
          <div className="w-80 px-8 py-4 mx-auto bg-white mt-52 rounded-xl shadow-lg">
            <div className="flex align-center flex-col">
              <div className="text-lg mt-4">用户管理系统</div>

              <button
                type="button"
                className="border px-4 py-2 mt-6 rounded-lg bg-indigo-400 text-white hover:bg-indigo-600 focus:bg-indigo-700"
                onClick={async () => {
                  try {
                    await api.get("/envs");
                    navigate("/home/app");
                  } catch (e) {
                    window.location = `https://login.hemayanjing.com/?appid=${APPID}&pms=manage`;
                  }
                }}
              >
                登录
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
