import { useState, useEffect } from "react";
import { fromJS } from "immutable";
import { Link } from "react-router-dom";
import parseJSON from "date-fns/parseJSON";
import _differenceBy from "lodash/differenceBy";
import format from "date-fns/format";
import * as timeago from "timeago.js";
import { Transition, Menu } from "@headlessui/react";
import { api } from "../../lib/api";

const Role = () => {
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showAddedSuccess, setShowAddedSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showPermissionMapping, setShowPermissionMapping] = useState(false);

  const [addName, setAddName] = useState("");
  const [addDescription, setAddDescription] = useState("");
  const [editName, setEditName] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [editId, setEditId] = useState(0);
  const [errMsg, setErrMsg] = useState("");
  const [pmRoleId, setPMRoleId] = useState(0);

  const [envs, setEnvs] = useState(fromJS([]));
  const [roles, setRoles] = useState(fromJS([]));
  const [permsByEnv, setPermsByEnv] = useState(fromJS([]));
  const [permissionRefreshTick, setPermissionRefreshTick] = useState(
    Date.now()
  );

  useEffect(() => {
    (async () => {
      const result = await api.get("/roles");
      const envResult = await api.get("/envs");
      const envs = fromJS(envResult.data || []);
      setRoles(fromJS(result.data || []));
      setEnvs(envs);
    })();
  }, []);

  useEffect(() => {
    if (showPermissionMapping === true) {
      (async () => {
        const permissionResult = await api.get("/permissions");
        const rolePermissionResult = await api.get("/role_permissions", {
          params: {
            filter_by_role_id: pmRoleId,
          },
        });
        const allPerms = (permissionResult.data || []).map((v) => ({
          id: v.id,
          name: v.name,
        }));
        const hasPerms = (rolePermissionResult.data || []).map((v) => ({
          id: v.permission_id,
          env_id: v.env_id,
          name: allPerms.find((vv) => {
            //console.log(vv.id, v.permission_id)
            return vv.id === v.permission_id;
          }).name,
        }));
        const permsByEnv = envs.map((v) => {
          const filteredHasPerms = hasPerms.filter(
            (vv) => vv.env_id === v.get("id")
          );
          return fromJS({
            id: v.get("id"),
            name: v.get("name"),
            hasPerms: filteredHasPerms || [],
            hasntPerms:
              _differenceBy(allPerms, filteredHasPerms, (v) => v.id) || [],
          });
        });
        //console.log(permsByEnv.toJS());
        setPermsByEnv(permsByEnv);
      })();
    }
  }, [showPermissionMapping, permissionRefreshTick]); // eslint-disable-line  react-hooks/exhaustive-deps

  return (
    <div className="px-4 sm:px-6 md:px-0">
      <div className="py-6">
        <div className="block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8">
              <Link
                to="/home/config/permission"
                className="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
              >
                权限
              </Link>

              <Link
                to="/home/config/role"
                className="border-indigo-500 text-indigo-600 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
              >
                角色
              </Link>

              <Link
                to="/home/config/env"
                className="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
              >
                环境
              </Link>
            </nav>
          </div>
        </div>

        <div className="mt-10">
          <div className="space-y-1">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              应用角色
            </h3>
            <p className="max-w-2xl text-sm text-gray-500">
              用户在系统中的角色
            </p>
          </div>
          <div className="my-4">
            <button
              type="button"
              onClick={() => {
                setAddName("");
                setShowAdd(true);
              }}
              className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              添加角色
            </button>
          </div>
          <div className="flex flex-col mt-5">
            <div className="-my-2 sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          编号
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          名称
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          描述
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          创建时间
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 w-40 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          更新时间
                        </th>
                        <th scope="col" className="relative px-6 py-3">
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {roles.map((v, i) => {
                        return (
                          <tr key={i}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {v.get("id")}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {v.get("name")}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {v.get("description")}
                            </td>
                            <td
                              className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                              title={format(
                                parseJSON(v.get("created_at")),
                                "yyyy-MM-dd HH:mm:ss"
                              )}
                            >
                              {timeago.format(v.get("created_at"), "zh_CN")}
                            </td>
                            <td
                              className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                              title={format(
                                parseJSON(v.get("updated_at")),
                                "yyyy-MM-dd HH:mm:ss"
                              )}
                            >
                              {timeago.format(v.get("updated_at"), "zh_CN")}
                            </td>
                            <td className=" w-20 px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                              <button
                                type="button"
                                onClick={() => {
                                  setEditName(v.get("name"));
                                  setEditDescription(v.get("description"));
                                  setEditId(v.get("id"));
                                  setShowEdit(true);
                                }}
                                className="text-indigo-600 hover:text-indigo-900 focus:outline-none"
                              >
                                编辑
                              </button>

                              <button
                                type="button"
                                onClick={() => {
                                  setPMRoleId(v.get("id"));
                                  setShowPermissionMapping(true);
                                }}
                                className="pl-2 text-gray-600 hover:text-gray-900 focus:outline-none"
                              >
                                权限
                              </button>

                              <Menu>
                                {({ open }) => (
                                  <div className="relative inline">
                                    <Menu.Button className="pl-2 z-0 text-gray-600 hover:text-gray-900 focus:outline-none">
                                      删除
                                    </Menu.Button>
                                    <Transition
                                      show={open}
                                      enter="transition duration-100 ease-out"
                                      enterFrom="transform scale-95 opacity-0"
                                      enterTo="transform scale-100 opacity-100"
                                      leave="transition duration-75 ease-out"
                                      leaveFrom="transform scale-100 opacity-100"
                                      leaveTo="transform scale-95 opacity-0"
                                    >
                                      <Menu.Items
                                        static
                                        className="absolute right-0 z-30 shadow-lg  text-left bg-white origin-top-right outline-none w-36 border rounded-lg px-2.5 py-2"
                                      >
                                        <div className="text-red-400 text-sm">
                                          是否确认删除?
                                        </div>
                                        <span className="relative mt-3 z-0 inline-flex shadow-sm rounded-md">
                                          <Menu.Item
                                            type="button"
                                            as="button"
                                            onClick={async () => {
                                              try {
                                                await api.delete(
                                                  `/roles/${v.get("id")}`
                                                );
                                              } catch (e) {
                                                setErrMsg("删除出错");
                                                setShowError(true);
                                                return;
                                              }
                                              window.location.reload();
                                            }}
                                            className="relative text-xs bg-red-600 text-white inline-flex items-center px-2 py-1 rounded-l-md border border-gray-300 font-medium hover:bg-red-800 hover:text-white focus:z-10 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500"
                                          >
                                            确定删除
                                          </Menu.Item>
                                          <Menu.Item
                                            as="button"
                                            type="button"
                                            className="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                          >
                                            取消
                                          </Menu.Item>
                                        </span>
                                      </Menu.Items>
                                    </Transition>
                                  </div>
                                )}
                              </Menu>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Transition show={showAdd}>
        <div
          className="fixed z-10 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                onClick={() => setShowAdd(false)}
                aria-hidden="true"
              ></div>
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="mt-3 text-center sm:mt-5">
                <div>
                  <label
                    htmlFor="name"
                    className="block text-left text-sm font-medium text-gray-700"
                  >
                    角色名称
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="name"
                      autoFocus
                      value={addName}
                      onChange={(e) => setAddName(e.target.value)}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="eg. admin"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <div>
                  <label
                    htmlFor="description"
                    className="block text-left text-sm font-medium text-gray-700"
                  >
                    角色描述
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="description"
                      value={addDescription}
                      onChange={(e) => setAddDescription(e.target.value)}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="eg. 管理员"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  onClick={async () => {
                    if (!addName || addName.length === 0) {
                      setErrMsg("请输入正确的角色名称");
                      setShowError(true);
                      return;
                    }

                    try {
                      await api.post("/roles", {
                        name: addName,
                        description: addDescription,
                      });
                    } catch (e) {
                      setErrMsg(e.message);
                      setShowError(true);
                      return;
                    }

                    setShowAdd(false);
                    setShowAddedSuccess(true);
                  }}
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                >
                  保存
                </button>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Transition>
      <Transition show={showPermissionMapping}>
        <div
          className="fixed z-10 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                onClick={() => setShowPermissionMapping(false)}
                aria-hidden="true"
              ></div>
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {permsByEnv.map((env) => {
                return (
                  <div key={env.get("id")}>
                    <div className="border-b border-gray-200">
                      <div className="-mb-px flex space-x-8">
                        <span className="border-indigo-500 text-indigo-600 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm">
                          {env.get("name")} 环境
                        </span>
                      </div>
                    </div>

                    <div
                      className={env.get("hasPerms").size === 0 ? "hidden" : ""}
                    >
                      <div className="text-sm font-medium text-gray-700 pt-2 pb-1">
                        已拥有权限
                      </div>
                      <div className="flex flex-col">
                        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                              <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                  <tr>
                                    <th
                                      scope="col"
                                      className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      权限名称
                                    </th>
                                    <th
                                      scope="col"
                                      className="relative px-6 py-3"
                                    >
                                      <span className="sr-only">Edit</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                  {env.get("hasPerms").map((v) => {
                                    return (
                                      <tr key={v.get("id")}>
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                          {v.get("name")}
                                        </td>
                                        <td className="px-4 py-2 whitespace-nowrap text-right text-sm font-medium">
                                          <button
                                            type="button"
                                            onClick={async () => {
                                              await api.delete(
                                                "/role_permissions/by_fields",
                                                {
                                                  params: {
                                                    env_id: env.get("id"),
                                                    role_id: pmRoleId,
                                                    permission_id: v.get("id"),
                                                  },
                                                }
                                              );
                                              setPermissionRefreshTick(
                                                Date.now()
                                              );
                                            }}
                                            className="text-red-600 hover:text-red-900 focus:outline-none"
                                          >
                                            移除
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className={
                        env.get("hasntPerms").size === 0 ? "hidden" : ""
                      }
                    >
                      <div className="text-sm font-medium text-gray-700 pt-2 pb-1">
                        未拥有权限
                      </div>
                      <div className="flex flex-col">
                        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                              <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                  <tr>
                                    <th
                                      scope="col"
                                      className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                      权限名称
                                    </th>
                                    <th
                                      scope="col"
                                      className="relative px-6 py-3"
                                    >
                                      <span className="sr-only">Edit</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                  {env.get("hasntPerms").map((v) => {
                                    return (
                                      <tr key={v.get("id")}>
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                          {v.get("name")}
                                        </td>
                                        <td className="px-4 py-2 whitespace-nowrap text-right text-sm font-medium">
                                          <button
                                            type="button"
                                            onClick={async () => {
                                              await api.post(
                                                "/role_permissions",
                                                {
                                                  env_id: env.get("id"),
                                                  role_id: pmRoleId,
                                                  permission_id: v.get("id"),
                                                }
                                              );
                                              setPermissionRefreshTick(
                                                Date.now()
                                              );
                                            }}
                                            className="text-indigo-600 hover:text-indigo-900 focus:outline-none"
                                          >
                                            添加
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  onClick={() => {
                    setShowPermissionMapping(false);
                  }}
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                >
                  关闭
                </button>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Transition>
      <Transition show={showEdit}>
        <div
          className="fixed z-10 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                onClick={() => {
                  setShowEdit(false);
                }}
                aria-hidden="true"
              ></div>
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="mt-3 text-center sm:mt-5">
                <div>
                  <label
                    htmlFor="editName"
                    className="block text-left text-sm font-medium text-gray-700"
                  >
                    角色名称
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="editName"
                      value={editName}
                      onChange={(e) => setEditName(e.target.value)}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="eg. admin"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <div>
                  <label
                    htmlFor="editName"
                    className="block text-left text-sm font-medium text-gray-700"
                  >
                    角色描述
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="editName"
                      value={editDescription}
                      onChange={(e) => setEditDescription(e.target.value)}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="eg. admin"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  onClick={async () => {
                    try {
                      await api.put(`/roles/${editId}`, {
                        name: editName,
                        description: editDescription,
                      });
                    } catch (e) {
                      setErrMsg("更新失败");
                      setShowError(true);
                      return;
                    }
                    window.location.reload();
                  }}
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                >
                  修改
                </button>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Transition>
      <Transition show={showAddedSuccess}>
        <div
          className="fixed z-10 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                aria-hidden="true"
              ></div>
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <svg
                    className="h-6 w-6 text-green-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <h3
                    className="text-lg leading-6 font-medium text-gray-900"
                    id="modal-title"
                  >
                    添加成功
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">角色添加成功</p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  onClick={() => {
                    setShowAddedSuccess(false);
                    window.location.reload();
                  }}
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                >
                  确认
                </button>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Transition>
      <Transition show={showError}>
        <div
          className="fixed z-10 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                aria-hidden="true"
              ></div>
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                  <svg
                    className="h-6 w-6 text-red-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    错误
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">{errMsg}</p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  onClick={() => {
                    setErrMsg("");
                    setShowError(false);
                  }}
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                >
                  确定
                </button>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default Role;
