import { useState, useEffect } from "react";
import { fromJS } from "immutable";
import parseJSON from "date-fns/parseJSON";
import _differenceBy from "lodash/differenceBy";
import { v4 as uuidv4 } from "uuid";
import CopyButton from "../copy-button/CopyButton";
import format from "date-fns/format";
import * as timeago from "timeago.js";
import { Menu, Transition } from "@headlessui/react";
import { api } from "../../lib/api";

const AppContent = () => {
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showAddedSuccess, setShowAddedSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showAllowedPermissionMapping, setShowAllowedPermissionMapping] =
    useState(false);

  const [addName, setAddName] = useState("");
  const [addDescription, setAddDescription] = useState("");
  const [addCallbackUrl, setAddCallbackUrl] = useState("");
  const [addAppid, setAddAppid] = useState("");
  const [addAppsecret, setAddAppsecret] = useState("");
  const [addEnvId, setAddEnvId] = useState(0);
  const [editId, setEditId] = useState(0);
  const [editName, setEditName] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [editCallbackUrl, setEditCallbackUrl] = useState("");
  const [editAppid, setEditAppid] = useState("");
  const [editAppsecret, setEditAppsecret] = useState("");
  const [editEnvId, setEditEnvId] = useState(0);
  const [rpAppId, setRpAppId] = useState(0);
  const [errMsg, setErrMsg] = useState("");

  const [apps, setApps] = useState(fromJS([]));
  const [envs, setEnvs] = useState(fromJS([]));
  const [hasPermissions, setHasPermissions] = useState(fromJS([]));
  const [hasntPermissions, setHasntPermissions] = useState(fromJS([]));

  const [rpRefreshTick, setRpRefreshTick] = useState(Date.now());

  useEffect(() => {
    (async () => {
      const result = await api.get("/apps");
      const envResult = await api.get("/envs");

      setApps(fromJS(result.data || []));
      setEnvs(fromJS(envResult.data || []));
    })();
  }, []);

  useEffect(() => {
    if (showAllowedPermissionMapping === true) {
      (async () => {
        const permissionResult = await api.get("/permissions");
        const rpResult = await api.get("/app_allowed_request_permissions", {
          params: {
            filter_by_app_id: rpAppId,
          },
        });
        const allPermissions = (permissionResult.data || []).map((v) => ({
          id: v.id,
          name: v.name,
        }));
        const hasPermissions = (rpResult.data || []).map((v) => ({
          id: v.permission_id,
          name: allPermissions.find((vv) => {
            return vv.id === v.permission_id;
          }).name,
        }));

        const hasntPermissions = _differenceBy(
          allPermissions,
          hasPermissions,
          (v) => v.id
        );
        //console.log(allPermissions, hasPermissions, hasntPermissions)
        setHasPermissions(fromJS(hasPermissions));
        setHasntPermissions(fromJS(hasntPermissions));
      })();
    }
  }, [showAllowedPermissionMapping, rpRefreshTick]); // eslint-disable-line  react-hooks/exhaustive-deps

  return (
    <div className="px-4 sm:px-6 md:px-0">
      <div className="py-6">
        <div>
          <p className="max-w-2xl text-sm text-gray-500">系统中的应用</p>
        </div>
        <div className="my-4">
          <button
            type="button"
            onClick={() => {
              setAddName("");
              setAddDescription("");
              setAddCallbackUrl("https://");
              setAddAppid(uuidv4().replace(/-/g, ""));
              setAddAppsecret(uuidv4().replace(/-/g, ""));
              setAddEnvId(envs.getIn([0, "id"], 0));
              setShowAdd(true);
            }}
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            添加应用
          </button>
        </div>
        <div className="flex flex-col mt-5">
          <div className="-my-2 sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        编号
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        应用名称
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        环境
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        描述
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        APPID
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        APPSECRET
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        创建时间
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-2 w-28 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        更新时间
                      </th>
                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {apps.map((v, i) => {
                      return (
                        <tr key={i}>
                          <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                            {v.get("id")}
                          </td>
                          <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                            {v.get("name")}
                          </td>
                          <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                            {envs
                              .filter((vv) => vv.get("id") === v.get("env_id"))
                              .getIn([0, "name"], "")}
                          </td>
                          <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                            {v.get("description")}
                          </td>
                          <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                            <CopyButton
                              onClick={(e) =>
                                navigator.clipboard.writeText(v.get("appid"))
                              }
                            >
                              {v.get("appid").slice(0, 6) +
                                "..." +
                                v.get("appid").slice(-2)}
                            </CopyButton>
                          </td>
                          <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                            <CopyButton
                              onClick={(e) =>
                                navigator.clipboard.writeText(
                                  v.get("appsecret")
                                )
                              }
                            >
                              {v.get("appsecret").slice(0, 6) +
                                "..." +
                                v.get("appsecret").slice(-2)}
                            </CopyButton>
                          </td>
                          <td
                            className="px-4 py-2 whitespace-nowrap text-sm text-gray-500"
                            title={format(
                              parseJSON(v.get("created_at")),
                              "yyyy-MM-dd HH:mm:ss"
                            )}
                          >
                            {timeago.format(v.get("created_at"), "zh_CN")}
                          </td>
                          <td
                            className="px-4 py-2 whitespace-nowrap text-sm text-gray-500"
                            title={format(
                              parseJSON(v.get("updated_at")),
                              "yyyy-MM-dd HH:mm:ss"
                            )}
                          >
                            {timeago.format(v.get("updated_at"), "zh_CN")}
                          </td>
                          <td className="px-4 py-2 whitespace-nowrap text-right text-sm font-medium">
                            <button
                              type="button"
                              onClick={() => {
                                setEditId(v.get("id"));
                                setEditName(v.get("name"));
                                setEditDescription(v.get("description"));
                                setEditCallbackUrl(v.get("callback_url"));
                                setEditAppid(v.get("appid"));
                                setEditAppsecret(v.get("appsecret"));
                                setEditEnvId(v.get("env_id"));
                                setShowEdit(true);
                              }}
                              className="text-indigo-600 hover:text-indigo-900 focus:outline-none"
                            >
                              编辑
                            </button>

                            <button
                              type="button"
                              onClick={() => {
                                setRpAppId(v.get("id"));
                                setShowAllowedPermissionMapping(true);
                              }}
                              className="pl-2 text-gray-600 hover:text-gray-900 focus:outline-none"
                            >
                              允许权限
                            </button>

                            <Menu>
                              {({ open }) => (
                                <div className="relative inline">
                                  <Menu.Button className="pl-2 z-0 text-gray-600 hover:text-gray-900 focus:outline-none">
                                    删除
                                  </Menu.Button>
                                  <Transition
                                    show={open}
                                    enter="transition duration-100 ease-out"
                                    enterFrom="transform scale-95 opacity-0"
                                    enterTo="transform scale-100 opacity-100"
                                    leave="transition duration-75 ease-out"
                                    leaveFrom="transform scale-100 opacity-100"
                                    leaveTo="transform scale-95 opacity-0"
                                  >
                                    <Menu.Items
                                      static
                                      className="absolute right-0 z-30 shadow-lg  text-left bg-white origin-top-right outline-none w-36 border rounded-lg px-2.5 py-2"
                                    >
                                      <div className="text-red-400 text-sm">
                                        是否确认删除?
                                      </div>
                                      <span className="relative mt-3 z-0 inline-flex shadow-sm rounded-md">
                                        <Menu.Item
                                          type="button"
                                          as="button"
                                          onClick={async () => {
                                            try {
                                              await api.delete(
                                                `/apps/${v.get("id")}`
                                              );
                                            } catch (e) {
                                              setErrMsg("删除出错");
                                              setShowError(true);
                                              return;
                                            }
                                            window.location.reload();
                                          }}
                                          className="relative text-xs bg-red-600 text-white inline-flex items-center px-2 py-1 rounded-l-md border border-gray-300 font-medium hover:bg-red-800 hover:text-white focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                        >
                                          确定删除
                                        </Menu.Item>
                                        <Menu.Item
                                          as="button"
                                          type="button"
                                          className="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                        >
                                          取消
                                        </Menu.Item>
                                      </span>
                                    </Menu.Items>
                                  </Transition>
                                </div>
                              )}
                            </Menu>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Transition show={showAdd}>
        <div
          className="fixed z-10 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                onClick={() => setShowAdd(false)}
                aria-hidden="true"
              ></div>
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="mt-3 text-center sm:mt-5">
                <div>
                  <label
                    htmlFor="name"
                    className="block text-left text-sm font-medium text-gray-700"
                  >
                    应用名称
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="name"
                      autoFocus
                      value={addName}
                      onChange={(e) => setAddName(e.target.value)}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="eg. POS生产环境"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <div>
                  <label
                    htmlFor="description"
                    className="block text-left text-sm font-medium text-gray-700"
                  >
                    描述
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="description"
                      value={addDescription}
                      onChange={(e) => setAddDescription(e.target.value)}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
              <div className="mt-3 sm:mt-5">
                <div>
                  <label
                    htmlFor="env"
                    className="block text-sm text-left font-medium text-gray-700"
                  >
                    应用环境
                  </label>
                  <select
                    name="env"
                    value={addEnvId}
                    onChange={(e) => setAddEnvId(e.target.value)}
                    className="shadow-sm mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  >
                    {envs.map((v) => {
                      return (
                        <option key={v.get("id")} value={v.get("id")}>
                          {v.get("name")}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="mt-3 text-center sm:mt-5">
                <div>
                  <label
                    htmlFor="description"
                    className="block text-left text-sm font-medium text-gray-700"
                  >
                    回调地址
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="description"
                      value={addCallbackUrl}
                      onChange={(e) => setAddCallbackUrl(e.target.value)}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>

              <div className="mt-3 text-center sm:mt-5">
                <div>
                  <label
                    htmlFor="appid"
                    className="block text-left text-sm font-medium text-gray-700"
                  >
                    appid
                  </label>
                  <div className="mt-1 flex shadow-sm">
                    <div className="focus-within:z-10 flex-grow">
                      <input
                        type="text"
                        name="appid"
                        disabled
                        value={addAppid}
                        onChange={(e) => setAddAppid(e.target.value)}
                        className="text-gray-400 bg-gray-100 focus:ring-indigo-500 focus:border-indigo-500 inline-flex w-full sm:text-sm border-gray-300 rounded-none rounded-l-md"
                        placeholder=""
                      />
                    </div>
                    <button
                      onClick={() => setAddAppid(uuidv4().replace(/-/g, ""))}
                      className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                    >
                      <svg
                        className="h-4 w-4 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>

              <div className="mt-3 text-center sm:mt-5">
                <div>
                  <label
                    htmlFor="appsecret"
                    className="block text-left text-sm font-medium text-gray-700"
                  >
                    appsecret
                  </label>
                  <div className="mt-1 flex shadow-sm">
                    <div className="focus-within:z-10 flex-grow">
                      <input
                        type="text"
                        disabled
                        name="appsecret"
                        value={addAppsecret}
                        onChange={(e) => setAddAppsecret(e.target.value)}
                        className="text-gray-400 bg-gray-100 focus:ring-indigo-500 focus:border-indigo-500 inline-flex w-full sm:text-sm border-gray-300 rounded-none rounded-l-md"
                        placeholder=""
                      />
                    </div>
                    <button
                      onClick={() =>
                        setAddAppsecret(uuidv4().replace(/-/g, ""))
                      }
                      className="-ml-px relative iline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                    >
                      <svg
                        className="h-4 w-4 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  onClick={async () => {
                    if (!addName || addName.length === 0) {
                      setErrMsg("请输入正确的应用名称");
                      setShowError(true);
                      return;
                    }
                    if (!addEnvId || addEnvId.length === 0) {
                      setErrMsg("请选择正确的环境");
                      setShowError(true);
                      return;
                    }

                    try {
                      await api.post("/apps", {
                        name: addName,
                        description: addDescription,
                        callback_url: addCallbackUrl,
                        appid: addAppid,
                        appsecret: addAppsecret,
                        env_id: addEnvId,
                      });
                    } catch (e) {
                      setErrMsg(e.message);
                      setShowError(true);
                      return;
                    }

                    setShowAdd(false);
                    setShowAddedSuccess(true);
                  }}
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                >
                  保存
                </button>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Transition>
      <Transition show={showEdit}>
        <div
          className="fixed z-10 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                onClick={() => {
                  setShowEdit(false);
                }}
                aria-hidden="true"
              ></div>
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="mt-3 text-center sm:mt-5">
                <div>
                  <label
                    htmlFor="name"
                    className="block text-left text-sm font-medium text-gray-700"
                  >
                    应用名称
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="name"
                      autoFocus
                      value={editName}
                      onChange={(e) => setEditName(e.target.value)}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="eg. POS生产环境"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <div>
                  <label
                    htmlFor="description"
                    className="block text-left text-sm font-medium text-gray-700"
                  >
                    描述
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="description"
                      value={editDescription}
                      onChange={(e) => setEditDescription(e.target.value)}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
              <div className="mt-3 sm:mt-5">
                <div>
                  <label
                    htmlFor="env"
                    className="block text-sm text-left font-medium text-gray-700"
                  >
                    应用环境
                  </label>
                  <select
                    name="env"
                    value={editEnvId}
                    onChange={(e) => setEditEnvId(e.target.value)}
                    className="shadow-sm mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  >
                    {envs.map((v) => {
                      return (
                        <option key={v.get("id")} value={v.get("id")}>
                          {v.get("name")}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="mt-3 text-center sm:mt-5">
                <div>
                  <label
                    htmlFor="description"
                    className="block text-left text-sm font-medium text-gray-700"
                  >
                    回调地址
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="description"
                      value={editCallbackUrl}
                      onChange={(e) => setEditCallbackUrl(e.target.value)}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>

              <div className="mt-3 text-center sm:mt-5">
                <div>
                  <label
                    htmlFor="appid"
                    className="block text-left text-sm font-medium text-gray-700"
                  >
                    appid
                  </label>
                  <div className="mt-1 flex shadow-sm">
                    <div className="focus-within:z-10 flex-grow">
                      <input
                        type="text"
                        name="appid"
                        disabled
                        value={editAppid}
                        onChange={(e) => setEditAppid(e.target.value)}
                        className="text-gray-400 bg-gray-100 focus:ring-indigo-500 focus:border-indigo-500 inline-flex w-full sm:text-sm border-gray-300 rounded-none rounded-l-md"
                        placeholder=""
                      />
                    </div>
                    <button
                      onClick={() => setEditAppid(uuidv4().replace(/-/g, ""))}
                      className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                    >
                      <svg
                        className="h-4 w-4 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>

              <div className="mt-3 text-center sm:mt-5">
                <div>
                  <label
                    htmlFor="appsecret"
                    className="block text-left text-sm font-medium text-gray-700"
                  >
                    appsecret
                  </label>
                  <div className="mt-1 flex shadow-sm">
                    <div className="focus-within:z-10 flex-grow">
                      <input
                        type="text"
                        disabled
                        name="appsecret"
                        value={editAppsecret}
                        onChange={(e) => setEditAppsecret(e.target.value)}
                        className="text-gray-400 bg-gray-100 focus:ring-indigo-500 focus:border-indigo-500 inline-flex w-full sm:text-sm border-gray-300 rounded-none rounded-l-md"
                        placeholder=""
                      />
                    </div>
                    <button
                      onClick={() =>
                        setEditAppsecret(uuidv4().replace(/-/g, ""))
                      }
                      className="-ml-px relative iline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                    >
                      <svg
                        className="h-4 w-4 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>

              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  onClick={async () => {
                    if (!editName || editName.length === 0) {
                      setErrMsg("请输入正确应用名称");
                      setShowError(true);
                      return;
                    }
                    try {
                      await api.put(`/apps/${editId}`, {
                        name: editName,
                        description: editDescription,
                        callback_url: editCallbackUrl,
                        appid: editAppid,
                        appsecret: editAppsecret,
                        env_id: editEnvId,
                      });
                    } catch (e) {
                      setErrMsg("更新失败");
                      setShowError(true);
                      return;
                    }
                    window.location.reload();
                  }}
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                >
                  修改
                </button>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Transition>
      <Transition show={showAllowedPermissionMapping}>
        <div
          className="fixed z-10 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                onClick={() => setShowAllowedPermissionMapping(false)}
                aria-hidden="true"
              ></div>
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className={hasPermissions.size === 0 ? "hidden" : ""}>
                <div className="text-sm font-medium text-gray-700 pt-2 pb-1">
                  允许申请的权限
                </div>
                <div className="flex flex-col">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                权限名称
                              </th>
                              <th scope="col" className="relative px-6 py-3">
                                <span className="sr-only">Edit</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {hasPermissions.map((v) => {
                              return (
                                <tr key={v.get("id")}>
                                  <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {v.get("name")}
                                  </td>
                                  <td className="px-4 py-2 whitespace-nowrap text-right text-sm font-medium">
                                    <button
                                      type="button"
                                      onClick={async () => {
                                        await api.delete(
                                          "/app_allowed_request_permissions/by_fields",
                                          {
                                            params: {
                                              app_id: rpAppId,
                                              permission_id: v.get("id"),
                                            },
                                          }
                                        );
                                        setRpRefreshTick(Date.now());
                                      }}
                                      className="text-red-600 hover:text-red-900 focus:outline-none"
                                    >
                                      移除
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={hasntPermissions.size === 0 ? "hidden" : ""}>
                <div className="text-sm font-medium text-gray-700 pt-2 pb-1">
                  不允许申请的权限
                </div>
                <div className="flex flex-col">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                权限名称
                              </th>
                              <th scope="col" className="relative px-6 py-3">
                                <span className="sr-only">Edit</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {hasntPermissions.map((v) => {
                              return (
                                <tr key={v.get("id")}>
                                  <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {v.get("name")}
                                  </td>
                                  <td className="px-4 py-2 whitespace-nowrap text-right text-sm font-medium">
                                    <button
                                      type="button"
                                      onClick={async () => {
                                        await api.post(
                                          "/app_allowed_request_permissions",
                                          {
                                            permission_id: v.get("id"),
                                            app_id: rpAppId,
                                          }
                                        );
                                        setRpRefreshTick(Date.now());
                                      }}
                                      className="text-indigo-600 hover:text-indigo-900 focus:outline-none"
                                    >
                                      添加
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  onClick={() => {
                    setShowAllowedPermissionMapping(false);
                  }}
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                >
                  关闭
                </button>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Transition>
      <Transition show={showAddedSuccess}>
        <div
          className="fixed z-10 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                aria-hidden="true"
              ></div>
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <svg
                    className="h-6 w-6 text-green-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <h3
                    className="text-lg leading-6 font-medium text-gray-900"
                    id="modal-title"
                  >
                    添加成功
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">用户添加成功</p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  onClick={() => {
                    setShowAddedSuccess(false);
                    window.location.reload();
                  }}
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                >
                  确认
                </button>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Transition>
      <Transition show={showError}>
        <div
          className="fixed z-10 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                aria-hidden="true"
              ></div>
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                  <svg
                    className="h-6 w-6 text-red-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    错误
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">{errMsg}</p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  onClick={() => {
                    setErrMsg("");
                    setShowError(false);
                  }}
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                >
                  确定
                </button>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default AppContent;
