import { Outlet } from "react-router-dom";
const Config = () => {
  return (
    <main className="flex-1 overflow-y-auto focus:outline-none" tabIndex="0">
      <div className="relative max-w-4xl mx-auto md:px-8 xl:px-0">
        <div className="pt-10 pb-16">
          <div className="px-4 sm:px-6 md:px-0">
            <h1 className="text-3xl font-extrabold text-gray-900">设置</h1>
          </div>
          <Outlet />
        </div>
      </div>
    </main>
  );
};

export default Config;
