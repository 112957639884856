import { useState, useRef } from "react";
import { Transition } from "@headlessui/react";

const CopyButton = ({ children, onClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const timer = useRef(null);
  return (
    <div className="relative">
      <button
        type="button"
        className="focus:outline-none text-indigo-500"
        onClick={(e) => {
          setIsOpen(true);
          if (timer.current) {
            clearTimeout(timer.current);
            timer.current = null;
          }
          timer.current = setTimeout(() => {
            setIsOpen(false);
          }, 800);

          onClick(e);
        }}
      >
        {children}
      </button>
      <Transition
        show={isOpen}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="absolute px-2 py-1 bg-white border shadow-md rounded-lg z-20"
      >
        已拷贝至剪切板
      </Transition>
    </div>
  );
};

export default CopyButton;
