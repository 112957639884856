import { Link, Outlet, useLocation } from "react-router-dom";
import { api } from "../../lib/api";

const Home = () => {
  const location = useLocation();
  const pathname = location.pathname;
  return (
    <div className="h-screen bg-white overflow-hidden flex">
      <div className="flex flex-shrink-0">
        <div className="w-64 flex flex-col">
          <nav className="bg-gray-50 border-r border-gray-200 pt-5 pb-4 flex flex-col flex-grow overflow-y-auto">
            <div className="px-4 text-center text-2xl font-semibold">
              MANAGE
            </div>
            <div className="flex-grow mt-5 flex flex-col">
              <div className="flex-1 space-y-1">
                <Link
                  to="/home/app"
                  className={
                    pathname.startsWith("/home/app")
                      ? "bg-indigo-50 border-indigo-600 text-indigo-600 group border-l-4 py-2 px-3 flex items-center text-sm font-medium"
                      : "border-transparent text-gray-600 hover:text-gray-900 hover:bg-gray-50 group border-l-4 py-2 px-3 flex items-center text-sm font-medium"
                  }
                >
                  <svg
                    className={
                      pathname.startsWith("/home/app")
                        ? "text-indigo-500 mr-3 h-6 w-6"
                        : "text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6"
                    }
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"
                    />
                  </svg>
                  App
                </Link>

                <Link
                  to="/home/users"
                  className={
                    pathname.startsWith("/home/users")
                      ? "bg-indigo-50 border-indigo-600 text-indigo-600 group border-l-4 py-2 px-3 flex items-center text-sm font-medium"
                      : "border-transparent text-gray-600 hover:text-gray-900 hover:bg-gray-50 group border-l-4 py-2 px-3 flex items-center text-sm font-medium"
                  }
                >
                  <svg
                    className={
                      pathname.startsWith("/home/users")
                        ? "text-indigo-500 mr-3 h-6 w-6"
                        : "text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6"
                    }
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                    />
                  </svg>
                  用户
                </Link>

                <Link
                  to="/home/config/permission"
                  className={
                    pathname.startsWith("/home/config")
                      ? "bg-indigo-50 border-indigo-600 text-indigo-600 group border-l-4 py-2 px-3 flex items-center text-sm font-medium"
                      : "border-transparent text-gray-600 hover:text-gray-900 hover:bg-gray-50 group border-l-4 py-2 px-3 flex items-center text-sm font-medium"
                  }
                >
                  <svg
                    className={
                      pathname.startsWith("/home/config")
                        ? "text-indigo-500 mr-3 h-6 w-6"
                        : "text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6"
                    }
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                  设置
                </Link>
                <Link
                  to="/home/devsign"
                  className={
                    pathname.startsWith("/home/devsign")
                      ? "bg-indigo-50 border-indigo-600 text-indigo-600 group border-l-4 py-2 px-3 flex items-center text-sm font-medium"
                      : "border-transparent text-gray-600 hover:text-gray-900 hover:bg-gray-50 group border-l-4 py-2 px-3 flex items-center text-sm font-medium"
                  }
                >
                  <svg
                    className={
                      pathname.startsWith("/home/config")
                        ? "text-indigo-500 mr-3 h-6 w-6"
                        : "text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6"
                    }
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                    ></path>
                  </svg>
                  签名
                </Link>
              </div>
            </div>
            <div className="flex-shrink-0 block w-full">
              <Link
                to="/"
                onClick={() => {
                  window.localStorage.accessToken = "";
                  window.localStorage.refreshToken = "";
                  api.defaults.headers.common["Authorization"] = "";
                }}
                className="group border-l-4 border-transparent py-2 px-3 flex items-center text-sm font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50"
              >
                <svg
                  className="text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
                退出
              </Link>
            </div>
          </nav>
        </div>
      </div>

      <div className="flex-1 flex flex-col">
        <Outlet />
      </div>
    </div>
  );
};

export default Home;
