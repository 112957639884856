import { useState, useEffect } from "react";
import { fromJS } from "immutable";
import parseJSON from "date-fns/parseJSON";
import { Transition, Menu } from "@headlessui/react";
import _differenceBy from "lodash/differenceBy";
import { v4 as uuidv4 } from "uuid";
import format from "date-fns/format";
import * as timeago from "timeago.js";
import { api } from "../../lib/api";

const WXAPPID = "wx2bbdcf55a5073a69";
const wxRedirectUrl =
  "https://login.hemayanjing.com/redirect_to_manage_hemayanjing_com_showWxId";

const UserContent = () => {
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showAddedSuccess, setShowAddedSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showRoleMapping, setShowRoleMapping] = useState(false);

  const [addUsername, setAddUsername] = useState("");
  const [addPassword, setAddPassword] = useState("");
  const [addDescription, setAddDescription] = useState("");
  const [addWxId, setAddWxId] = useState("");
  const [editId, setEditId] = useState(0);
  const [editUsername, setEditUsername] = useState("");
  const [editPassword, setEditPassword] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [editWxId, setEditWxId] = useState("");
  const [rmUserId, setRmUserId] = useState(0);
  const [errMsg, setErrMsg] = useState("");

  const [users, setUsers] = useState(fromJS([]));
  const [hasRoles, setHasRoles] = useState(fromJS([]));
  const [hasntRoles, setHasntRoles] = useState(fromJS([]));

  const [roleRefreshTick, setRoleRefreshTick] = useState(Date.now());

  useEffect(() => {
    (async () => {
      const result = await api.get("/users");

      setUsers(fromJS(result.data || []));
    })();
  }, []);

  useEffect(() => {
    if (showRoleMapping === true) {
      (async () => {
        const roleResult = await api.get("/roles");
        const userRolesResult = await api.get("/user_roles", {
          params: {
            filter_by_user_id: rmUserId,
          },
        });
        const allRoles = (roleResult.data || []).map((v) => ({
          id: v.id,
          name: v.name,
        }));
        const hasRoles = (userRolesResult.data || []).map((v) => ({
          id: v.role_id,
          name: allRoles.find((vv) => {
            return vv.id === v.role_id;
          }).name,
        }));
        const hasntRoles = _differenceBy(allRoles, hasRoles, (v) => v.id);
        setHasRoles(fromJS(hasRoles));
        setHasntRoles(fromJS(hasntRoles));
      })();
    }
  }, [showRoleMapping, roleRefreshTick]); // eslint-disable-line  react-hooks/exhaustive-deps

  return (
    <div className="px-4 sm:px-6 md:px-0">
      <div className="py-6">
        <div>
          <p className="max-w-2xl text-sm text-gray-500">
            系统中的用户，不区分测试环境和生产环境
          </p>
        </div>
        <div className="my-4">
          <button
            type="button"
            onClick={() => {
              setAddUsername("");
              setShowAdd(true);
            }}
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            添加用户
          </button>
          <a
            href={`https://open.weixin.qq.com/connect/qrconnect?appid=${WXAPPID}&redirect_uri=${encodeURIComponent(
              wxRedirectUrl
            )}&scope=snsapi_login&state=${uuidv4().replace(
              /-/g,
              ""
            )}#wechat_redirect`}
            target="_blank"
            rel="noreferrer"
            className="ml-3 inline-flex items-center px-2.5 py-1.5 border text-xs font-medium rounded shadow-sm text-indigo-500 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            微信ID获取助手
          </a>
        </div>
        <div className="flex flex-col mt-5">
          <div className="-my-2 sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        编号
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        用户名
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        描述
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        已绑微信
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        创建时间
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-2 w-28 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        更新时间
                      </th>
                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {users.map((v, i) => {
                      return (
                        <tr key={i}>
                          <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                            {v.get("id")}
                          </td>
                          <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                            {v.get("username")}
                          </td>
                          <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                            {v.get("description")}
                          </td>
                          <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                            {v.get("wx_unionid") ? "是" : "否"}
                          </td>
                          <td
                            className="px-4 py-2 whitespace-nowrap text-sm text-gray-500"
                            title={format(
                              parseJSON(v.get("created_at")),
                              "yyyy-MM-dd HH:mm:ss"
                            )}
                          >
                            {timeago.format(v.get("created_at"), "zh_CN")}
                          </td>
                          <td
                            className="px-4 py-2 whitespace-nowrap text-sm text-gray-500"
                            title={format(
                              parseJSON(v.get("updated_at")),
                              "yyyy-MM-dd HH:mm:ss"
                            )}
                          >
                            {timeago.format(v.get("updated_at"), "zh_CN")}
                          </td>
                          <td className="px-4 py-2 whitespace-nowrap text-right text-sm font-medium">
                            <button
                              type="button"
                              onClick={() => {
                                setEditId(v.get("id"));
                                setEditUsername(v.get("username"));
                                setEditPassword("");
                                setEditDescription(v.get("description"));
                                setEditWxId(v.get("wx_unionid"));
                                setShowEdit(true);
                              }}
                              className="text-indigo-600 hover:text-indigo-900 focus:outline-none"
                            >
                              编辑
                            </button>

                            <button
                              type="button"
                              onClick={() => {
                                setRmUserId(v.get("id"));
                                setShowRoleMapping(true);
                              }}
                              className="pl-2 text-gray-600 hover:text-gray-900 focus:outline-none"
                            >
                              角色
                            </button>

                            <Menu>
                              {({ open }) => (
                                <div className="relative inline">
                                  <Menu.Button className="pl-2 z-0 text-gray-600 hover:text-gray-900 focus:outline-none">
                                    删除
                                  </Menu.Button>
                                  <Transition
                                    show={open}
                                    enter="transition duration-100 ease-out"
                                    enterFrom="transform scale-95 opacity-0"
                                    enterTo="transform scale-100 opacity-100"
                                    leave="transition duration-75 ease-out"
                                    leaveFrom="transform scale-100 opacity-100"
                                    leaveTo="transform scale-95 opacity-0"
                                  >
                                    <Menu.Items
                                      static
                                      className="absolute right-0 z-30 shadow-lg  text-left bg-white origin-top-right outline-none w-36 border rounded-lg px-2.5 py-2"
                                    >
                                      <div className="text-red-400 text-sm">
                                        是否确认删除?
                                      </div>
                                      <span className="relative mt-3 z-0 inline-flex shadow-sm rounded-md">
                                        <Menu.Item
                                          type="button"
                                          as="button"
                                          onClick={async () => {
                                            try {
                                              await api.delete(
                                                `/users/${v.get("id")}`
                                              );
                                            } catch (e) {
                                              setErrMsg("删除出错");
                                              setShowError(true);
                                              return;
                                            }
                                            window.location.reload();
                                          }}
                                          className="relative text-xs bg-red-600 text-white inline-flex items-center px-2 py-1 rounded-l-md border border-gray-300 font-medium hover:bg-red-800 hover:text-white focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                        >
                                          确定删除
                                        </Menu.Item>
                                        <Menu.Item
                                          as="button"
                                          type="button"
                                          className="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                        >
                                          取消
                                        </Menu.Item>
                                      </span>
                                    </Menu.Items>
                                  </Transition>
                                </div>
                              )}
                            </Menu>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Transition show={showAdd}>
        <div
          className="fixed z-10 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                onClick={() => setShowAdd(false)}
                aria-hidden="true"
              ></div>
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="mt-3 text-center sm:mt-5">
                <div>
                  <label
                    htmlFor="username"
                    className="block text-left text-sm font-medium text-gray-700"
                  >
                    用户名
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="username"
                      autoFocus
                      value={addUsername}
                      onChange={(e) => setAddUsername(e.target.value)}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="eg. 13812341234"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <div>
                  <label
                    htmlFor="password"
                    className="block text-left text-sm font-medium text-gray-700"
                  >
                    密码
                  </label>
                  <div className="mt-1">
                    <input
                      type="password"
                      name="password"
                      value={addPassword}
                      onChange={(e) => setAddPassword(e.target.value)}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <div>
                  <label
                    htmlFor="description"
                    className="block text-left text-sm font-medium text-gray-700"
                  >
                    描述
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="description"
                      value={addDescription}
                      onChange={(e) => setAddDescription(e.target.value)}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="eg. 周杰伦"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <div>
                  <label
                    htmlFor="wx_unionid"
                    className="block text-left text-sm font-medium text-gray-700"
                  >
                    微信ID
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="wx_unionid"
                      value={addWxId}
                      onChange={(e) => setAddWxId(e.target.value)}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  onClick={async () => {
                    if (!addUsername || addUsername.length < 6) {
                      setErrMsg("请输入正确的用户名");
                      setShowError(true);
                      return;
                    }
                    if (!addPassword || addPassword.length < 6) {
                      setErrMsg("请输入正确的密码");
                      setShowError(true);
                      return;
                    }

                    try {
                      await api.post("/users", {
                        username: addUsername,
                        password: addPassword,
                        description: addDescription,
                        wx_unionid: addWxId,
                      });
                    } catch (e) {
                      setErrMsg(e.message);
                      setShowError(true);
                      return;
                    }

                    setShowAdd(false);
                    setShowAddedSuccess(true);
                  }}
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                >
                  保存
                </button>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Transition>
      <Transition show={showEdit}>
        <div
          className="fixed z-10 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                onClick={() => {
                  setShowEdit(false);
                }}
                aria-hidden="true"
              ></div>
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="mt-3 text-center sm:mt-5">
                <div>
                  <label
                    htmlFor="username"
                    className="block text-left text-sm font-medium text-gray-700"
                  >
                    用户名
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="username"
                      autoFocus
                      value={editUsername}
                      onChange={(e) => setEditUsername(e.target.value)}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="eg. 13812341234"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <div>
                  <label
                    htmlFor="password"
                    className="block text-left text-sm font-medium text-gray-700"
                  >
                    密码
                  </label>
                  <div className="mt-1">
                    <input
                      type="password"
                      name="password"
                      value={editPassword}
                      onChange={(e) => setEditPassword(e.target.value)}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="密码区置空则为不修改密码"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <div>
                  <label
                    htmlFor="description"
                    className="block text-left text-sm font-medium text-gray-700"
                  >
                    描述
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="description"
                      value={editDescription}
                      onChange={(e) => setEditDescription(e.target.value)}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="eg. 周杰伦"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <div>
                  <label
                    htmlFor="wx_unionid"
                    className="block text-left text-sm font-medium text-gray-700"
                  >
                    微信ID
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="wx_unionid"
                      value={editWxId}
                      onChange={(e) => setEditWxId(e.target.value)}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  onClick={async () => {
                    if (!editUsername || editUsername.length < 6) {
                      setErrMsg("请输入正确的用户名");
                      setShowError(true);
                      return;
                    }
                    if (editPassword !== "" && editPassword.length < 6) {
                      setErrMsg("请输入正确的密码");
                      setShowError(true);
                      return;
                    }
                    try {
                      await api.put(`/users/${editId}`, {
                        username: editUsername,
                        password: editPassword,
                        description: editDescription,
                        wx_unionid: editWxId,
                      });
                    } catch (e) {
                      setErrMsg("更新失败");
                      setShowError(true);
                      return;
                    }
                    window.location.reload();
                  }}
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                >
                  修改
                </button>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Transition>
      <Transition show={showRoleMapping}>
        <div
          className="fixed z-10 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                onClick={() => setShowRoleMapping(false)}
                aria-hidden="true"
              ></div>
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className={hasRoles.size === 0 ? "hidden" : ""}>
                <div className="text-sm font-medium text-gray-700 pt-2 pb-1">
                  已拥有角色
                </div>
                <div className="flex flex-col">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                角色名称
                              </th>
                              <th scope="col" className="relative px-6 py-3">
                                <span className="sr-only">Edit</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {hasRoles.map((v) => {
                              return (
                                <tr key={v.get("id")}>
                                  <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {v.get("name")}
                                  </td>
                                  <td className="px-4 py-2 whitespace-nowrap text-right text-sm font-medium">
                                    <button
                                      type="button"
                                      onClick={async () => {
                                        await api.delete(
                                          "/user_roles/by_fields",
                                          {
                                            params: {
                                              role_id: v.get("id"),
                                              user_id: rmUserId,
                                            },
                                          }
                                        );
                                        setRoleRefreshTick(Date.now());
                                      }}
                                      className="text-red-600 hover:text-red-900 focus:outline-none"
                                    >
                                      移除
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={hasntRoles.size === 0 ? "hidden" : ""}>
                <div className="text-sm font-medium text-gray-700 pt-2 pb-1">
                  未拥有角色
                </div>
                <div className="flex flex-col">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                              >
                                角色名称
                              </th>
                              <th scope="col" className="relative px-6 py-3">
                                <span className="sr-only">Edit</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {hasntRoles.map((v) => {
                              return (
                                <tr key={v.get("id")}>
                                  <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {v.get("name")}
                                  </td>
                                  <td className="px-4 py-2 whitespace-nowrap text-right text-sm font-medium">
                                    <button
                                      type="button"
                                      onClick={async () => {
                                        await api.post("/user_roles", {
                                          role_id: v.get("id"),
                                          user_id: rmUserId,
                                        });
                                        setRoleRefreshTick(Date.now());
                                      }}
                                      className="text-indigo-600 hover:text-indigo-900 focus:outline-none"
                                    >
                                      添加
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  onClick={() => {
                    setShowRoleMapping(false);
                  }}
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                >
                  关闭
                </button>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Transition>
      <Transition show={showAddedSuccess}>
        <div
          className="fixed z-10 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                aria-hidden="true"
              ></div>
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <svg
                    className="h-6 w-6 text-green-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <h3
                    className="text-lg leading-6 font-medium text-gray-900"
                    id="modal-title"
                  >
                    添加成功
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">用户添加成功</p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  onClick={() => {
                    setShowAddedSuccess(false);
                    window.location.reload();
                  }}
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                >
                  确认
                </button>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Transition>
      <Transition show={showError}>
        <div
          className="fixed z-10 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                aria-hidden="true"
              ></div>
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                  <svg
                    className="h-6 w-6 text-red-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    错误
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">{errMsg}</p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  onClick={() => {
                    setErrMsg("");
                    setShowError(false);
                  }}
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                >
                  确定
                </button>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Transition>
    </div>
  );
};

export default UserContent;
