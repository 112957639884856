import { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import _get from "lodash/get";
import { api } from "./lib/api";

import Login from "./components/login/Login";
import Home from "./components/home/Home";
import AppManage from "./components/app/AppManage";
import AppContent from "./components/app/AppContent";
import Users from "./components/user/User";
import UserContent from "./components/user/UserContent";
import Config from "./components/config/Config";
import Permission from "./components/config/Permission";
import Role from "./components/config/Role";
import Env from "./components/config/Env";
import DevSign from "./components/devsign/DevSign";

const ShowWxId = () => {
  const [wxId, setWxId] = useState("");
  useEffect(() => {
    const url = new URL(window.location.href);
    const code = url.searchParams.get("code");

    if (!code) {
      return;
    }

    (async function () {
      let res;
      try {
        res = await api.post("/fetch_wx_unionid", {
          code,
        });
      } catch (e) {
        let msg = _get(
          e,
          "response.data.message",
          _get(e, "response.data", e.message)
        );
        alert(msg);
        return;
      }
      setWxId(_get(res, "data.unionid", ""));
    })();
  }, []);
  return (
    <div className="container mx-auto h-screen">
      <div className="flex items-center mt-10">
        <div className="mr-4">微信UnionId: </div>
        <div>
          <input
            type="text"
            rows="4"
            className="py-3 px-4 block w-96 shadow-sm text-warm-gray-900 focus:ring-teal-500 focus:border-teal-500 border-warm-gray-300 rounded-md"
            value={wxId}
            readOnly
          />
        </div>
        <div>
          <button
            className="ml-4 inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
            type="button"
            onClick={() => {
              navigator.clipboard.writeText(wxId);
              setTimeout(() => alert("复制成功"), 100);
            }}
          >
            复制
          </button>
        </div>
      </div>
    </div>
  );
};

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/showWxId" element={<ShowWxId />} />
        <Route path="/home" element={<Home />}>
          <Route path="app" element={<AppManage />}>
            <Route path="" element={<AppContent />} />
          </Route>
          <Route path="users" element={<Users />}>
            <Route path="" element={<UserContent />} />
          </Route>
          <Route path="config" element={<Config />}>
            <Route path="permission" element={<Permission />} />
            <Route path="role" element={<Role />} />
            <Route path="env" element={<Env />} />
          </Route>
          <Route path="devsign" element={<DevSign />} />
        </Route>
      </Routes>
    </Router>
  );
}
